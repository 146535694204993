import { AxiosResponse } from "axios";
import classNames from "classnames";
import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { InView } from "react-intersection-observer";
import { useRecoilValue } from "recoil";

import { BannersResponse, ProductShort } from "@/api/generatedTypes";
import { PRODUCTS_PER_PAGE } from "@/api/ProductsApi";
import { CollectionBanner } from "@/components/CollectionBanner";
import { BANNER_URI } from "@/config";
import emptyFunction from "@/helpers/emptyFunction";
import { useDivScrollRestore } from "@/hooks/useDivScrollRestore";
import { useGoUpListener } from "@/hooks/useGoUpListener";

import { PageHeader } from "../../pages/filter";
import { Banner } from "../Banner";
import { BlockProductsEmpty } from "../BlockProductsEmpty";
import { appliedFiltersWithoutStores } from "../filter/appliedFiltersWithoutStores";
import { appliedSearchState } from "../filter/appliedSearchState";
import { ProductItem } from "../ProductItem";
import styles from "./Products.module.scss";

interface ProductsProps {
  className?: string;
  productsPages?: AxiosResponse<ProductShort[], unknown>[];
  fetchNextPage: () => void;
  isProductEmpty: boolean;
  isBlocksEmpty: boolean;
  isMainPage: boolean;
  banners?: BannersResponse;
  header?: PageHeader;
}

const Products = ({
  className,
  productsPages,
  fetchNextPage,
  isProductEmpty,
  isBlocksEmpty,
  isMainPage,
  banners,
  header,
}: ProductsProps) => {
  const appliedFilters = useRecoilValue(appliedFiltersWithoutStores);
  const appliedSearch = useRecoilValue(appliedSearchState);

  const router = useRouter();

  const { containerRef: contentTabRef, reset } = useDivScrollRestore(
    isMainPage ? "products" : "filterProducts"
  );
  useGoUpListener({ key: "products", contentTabRef, reset });

  useEffect(() => {
    if (contentTabRef.current) {
      contentTabRef.current.scrollTo(0, 0);
    }
  }, [appliedFilters, appliedSearch]);

  if (isProductEmpty)
    return (
      <BlockProductsEmpty
        isProductEmpty={isProductEmpty}
        isBlocksEmpty={isBlocksEmpty}
      />
    );

  const isFilterPage = Boolean(router.query.filter);

  return (
    <div
      className={classNames(styles.productsContainer, className)}
      ref={contentTabRef}
    >
      {BANNER_URI && !isFilterPage && (
        <Banner
          src={BANNER_URI}
          className={styles.banner}
          link={`/filter?filter=%7B"collections"%3A%5B"67654bff-8da6-4e9a-ba23-c3af0f5a5302"%5D%2C"search"%3A""%7D`}
        />
      )}
      {header && (
        <Banner
          src={header.banner}
          poster={header.poster}
          alt={header.alt}
          onClick={emptyFunction}
        />
      )}
      {Boolean(!BANNER_URI && banners?.collection_banners?.length) &&
        banners?.collection_banners.map((banner) => (
          <CollectionBanner banner={banner} key={banner.collection_id} />
        ))}
      {productsPages &&
        productsPages.map((page, pageIndex, pageArray) =>
          page.data.map((product, index) => {
            const isLastPage = pageArray.length - 1 === pageIndex;
            const isMiddleOfThePage = PRODUCTS_PER_PAGE / 2 + 1 === index + 1;

            return (
              <Fragment key={product.id}>
                {isLastPage && isMiddleOfThePage ? (
                  <InView
                    as="div"
                    onChange={(inView) => inView && fetchNextPage()}
                  >
                    <ProductItem product={product} />
                  </InView>
                ) : (
                  <ProductItem product={product} />
                )}
              </Fragment>
            );
          })
        )}
    </div>
  );
};

export default Products;
